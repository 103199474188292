import axios from 'axios'
import wx from 'wx'

const BASE_URL = '/wxmp/api/'

function jssdk(url) {
  return axios({
    url: `${BASE_URL}/Js`,
    method: 'post',
    data: { url: url }
  })
}

function wxLocate() {
  return new Promise(function (resolve, reject) {
    wx.getLocation({
      type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: function (res) {
        resolve(res)
        // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        // var speed = res.speed; // 速度，以米/每秒计
        // var accuracy = res.accuracy; // 位置精度
      },
      fail: function (err) {
        reject(err)
      }
    })
  })
}

function wxReady(url) {
  url = url || window.location.href.split('#')[0]

  return new Promise(function (resolve, reject) {
    jssdk(url).then(res => {
      if (res.status === 200 && res.data.Type === 200) {
        const params = res.data.Data

        wx.ready(function(){
          LAST_WX_CONFIG_URL = url
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          resolve()
        })
        wx.error(function(res){
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          reject(res)
        })
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: params.AppId, // 必填，公众号的唯一标识
          timestamp: params.Timestamp, // 必填，生成签名的时间戳
          nonceStr: params.NonceStr, // 必填，生成签名的随机串
          signature: params.Signature,// 必填，签名
          jsApiList: [
            'openLocation', 'getLocation'
          ] // 必填，需要使用的JS接口列表
        })
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

let LAST_WX_CONFIG_URL = null

function wxAutoReady(url) {
  url = url || window.location.href.split('#')[0]
  return new Promise(function (resolve, reject) {
    if (url === LAST_WX_CONFIG_URL) {
      resolve()
    } else {
      wxReady(url).then(resolve).catch(reject)
    }
  })
}

export {
  jssdk,
  wxReady,
  wxAutoReady,
  wxLocate
}
